import model from "@js/model.js";
import { getKeyByValue, addDirPath, isObject, removeEmptyFromArr, addQueryStringValues } from "@helpers/utils.js"
import Enum from "@shared/enum.js";
import { nextTick, reactive, watch } from "vue"

import mpRoutes from "@lang/mp-routes.js";

function getNonMultiPagePaths(){
    var paths = this.getPaths();
    var skipNum = this.getMultiPagePathsToSkip();
    paths.splice(skipNum, paths.length - skipNum);
    return paths;
}

function setupInitialUrlPaths(){
    model.initialUrlPath = this.getMultiPagePath();

    model.initialContext = null;
    model.initialSubContext = null;

    let paths = model.initialUrlPath.split('/').filter(x => x);

    let contextSubPath = paths[0];
    let subContextSubPath = paths[1];

    if(contextSubPath) model.initialContext = getEnumKeyName(contextSubPath);
    if(subContextSubPath) model.initialSubContext = getEnumKeyName(subContextSubPath);

    function getEnumKeyName(subPath){
        let enumPropName = null;

        Object.keys(mpRoutes).every(propName => {
            let langProps = mpRoutes[propName];
            Object.keys(langProps).every(langKey => {
                let val = langProps[langKey];
                if(val.toLowerCase() === subPath.toLowerCase()){
                    enumPropName = propName.replace('mpRoute_', '');
                    return false;
                }
                return true;
            });
            return !enumPropName;
        });

        return Enum.UIContexts[enumPropName] || null;
    }
}

export default {
    initMultiPage(){
        model.storeWebResInfo = model.isUI('FUND');
        model.pushLang(mpRoutes);

        if(typeof model.multipage !== 'boolean') model.multipage = false;
        if(typeof model.multipagePathsToSkip !== 'number') model.multipagePathsToSkip = 0;

        setupInitialUrlPaths.call(this);

        if(!model.multipage) return;

        let delayedReload = reactive({value: false});
        model.on('LangSaved', e => {
            if(e.prevLang !== model.langKey){
                if(delayedReload.value){
                    watch(() => delayedReload.value, value => {
                        if(value) return;
                        nextTick(() => location.reload());
                    });
                }
                else nextTick(() => location.reload());
            }
        });

        model.on('UIContextUpdated', 'GameStarted', 'GameEnded', 'GameDisplayed', 'SetLang', () => {
            if(model.isView(Enum.Views.BLOG)) this.whenBlogCatsFetched(() => inner.call(this));
            else inner.call(this);

            function inner(){
                var mpArr = [];
                if(model.isPage(Enum.Pages.PROMOS)){
                    if(model.promoSectionPageNavItem) this.multipageRedir([model.redeemLangObj(model.promoSectionPageNavItem.path)], false);
                }
                else if(model.getCurrContext()){
                    var context = getKeyByValue(Enum.UIContexts, model.getCurrContext());
                    var childContext = getKeyByValue(Enum.UIContexts, model.getCurrSubContext());

                    mpArr.push(model.getStr('mpRoute_' + context));
                    childContext && mpArr.push(model.getStr('mpRoute_' + childContext));

                    this.multipageRedir(mpArr, false);
                }
                else if(/*!model.isMobile && */this.isAGameDisplayed()){
                    mpArr.push(model.getStr('mpRoute_Game'));
                    model.logged ? mpArr.push(model.getStr('mpRoute_Game_Play')) : mpArr.push(model.getStr('mpRoute_Game_Demo'));

                    var gameBusiness = this.isAGameDisplayed();
                    if(gameBusiness){
                        var game = this.getGameByID(gameBusiness.game.id);
                        var providerDN = this.formatValueForPathname(this.getProviderDN(this.getProviderConfig(game)));
                        var gameName = this.formatValueForPathname(game.name);

                        mpArr.push(providerDN);
                        mpArr.push(gameName);

                        this.multipageRedir(mpArr, false);
                    }
                }
                else if(model.currCatIds.level1 === Enum.CATEGORY_IDS.PROMO_INFO && model.mpPromoLoad){
                    delayedReload.value = true;

                    this.sendPromoListRequestIfEmpty(() => {
                        this.getPromoByRoute(model.mpPromoLoad.route, model.mpPromoLoad.preRoute).then(res => {
                            let promo = res.promo;
                            if(!promo) return;

                            if(model.tournaments.all.find(item => item.id === promo.id)) mpArr.push(model.redeemLangObj(Enum.MultiPage.Tournaments));
                            else mpArr.push(model.redeemLangObj(Enum.MultiPage.Promotions));

                            res.preRouteLabel && mpArr.push(model.getStr(res.preRouteLabel));
                            mpArr.push(model.redeemLangObj(promo.routeAllLangs) || promo.id);
                            
                            this.multipageRedir(mpArr, false);
                            
                            delayedReload.value = false;
                        });
                    }, true);
                }
                else if(model.currCatIds.level1 === Enum.CATEGORY_IDS.WEBRES){
                    if(model.webResInfo?.path){
                        this.multipageRedir([model.redeemLangObj(model.webResInfo.path)]);
                    }
                }
                else if(model.isUI('FUND') && model.isPage(Enum.CATEGORY_IDS.PAGE_NOT_FOUND)){
                    mpArr.push('error');
                    this.multipageRedir(mpArr, false);
                }
                else if(model.isView(Enum.Views.MAIN)) this.multipageRedir(this.getCatPathsAllLevels(model.currCatIds), false);
                else if(model.isView(Enum.Views.BLOG)){
                    mpArr = this.getCatPathsAllLevels(model.currCatIds);
                    model.observables.blogArticleId && mpArr.push(model.observables.blogArticleId);
                    this.multipageRedir(mpArr, false);
                }
                else{
                    mpArr.push(model.initialUrlPath);
                    this.multipageRedir(mpArr, false);
                }
            }
        });
    },
    formatMultipagePathFromId(catID, noOrigin){
        let path = '#', value;

        let cat = this.getCatByCatID(catID);
        if(!cat) {
            value = path;
            return;
        }

        if(cat.path){
            path = model.redeemLangObj(cat.path);
            if(cat.parent) path = addDirPath(model.redeemLangObj(cat.parent.path), path);
            if(model.langAsPath && model.subDomain) path = addDirPath(model.subDomain.toLowerCase(), path);
        }
        else if(cat.promo){
            let promosSubpath = model.redeemLangObj(Enum.MultiPage.Promotions);
            path = promosSubpath;

            (() => {
                if(!Array.isArray(model.promoList)){
                    model.on('PromoListLoaded', e => {
                        e.dispose();
                        path = setupTargetPromo.call(this, promosSubpath);
                        value = this.formatMultipagePath(`/${path}`);
                    });
                }
                else path = setupTargetPromo.call(this, path);

                function setupTargetPromo(path){
                    let targetPromo = model.promos.all.find(promo => promo.id == cat.promo);
                    if(targetPromo && targetPromo.route) path = addDirPath(path, targetPromo.route);
                    if(model.langAsPath && model.subDomain) path = addDirPath(model.subDomain.toLowerCase(), path);
                    return path;
                }
            })();
        }
        
        value = this.formatMultipagePath(`/${path}`, noOrigin);
        return value;
    },
    formatValueForPathname(str){
        return (str + '').toLowerCase().replace(/[\W_]+/g,"-");
    },
    handleExternalPages(_tmpCats){
        let sitePath = this.getMultiPagePath();

        if(!Array.isArray(_tmpCats)) _tmpCats = [];

        if(!model.urls.webres) model.urls.webres = model.urls.footer;

        for(var key in model.urls.webres){
            var link = model.urls.webres[key];

            if(!isObject(link) || sitePath != model.redeemLangObj(link.path)) continue;

            model.storeWebResInfo = true;
            _tmpCats.push(Enum.CATEGORY_IDS.WEBRES);
            this.openFooterLink(key, null, false);
            this.setupInitialCats(_tmpCats);

            return true;
        }

        return false;
    },
    setupMultiPageAfterPageLoad(){
        if(!model.isView(Enum.Views.MAIN, Enum.Views.BLOG)){
            setupDefaultCats.call(this, false);
            return;
        }

        var multipage = this.getMultiPagePath();
        if(!multipage) setupDefaultCats.call(this, true);
        else{
            var catsLegit = true;
            var _tmpCats = [];

            multipage = multipage.split('/');
            if(multipage.length > 0){
                var firstPath = multipage[0];
                var secondPath = multipage[1];
                var thirdPath = multipage[2];
                var fourthPath = multipage[3];

                if(model.isUI('FUND') && firstPath == 'error'){
                    _tmpCats.push(Enum.CATEGORY_IDS.PAGE_NOT_FOUND);
                    setupCats.call(this);
                    return;
                }
                else if(firstPath == 'blog' && secondPath == 'article'){
                    _tmpCats.push(Enum.CATEGORY_IDS.BLOG);
                    _tmpCats.push('blog-article');
                    model.observables.blogArticleId = thirdPath;
                    setupCats.call(this);
                    return;
                }
                else if(firstPath == model.getStr('mpRoute_Game')){
                    this.fetchPreparedGames(() => {
                        if(secondPath == model.getStr('mpRoute_Game_Play') || secondPath == model.getStr('mpRoute_Game_Demo')){
                            var game = this.getGameByRoute(thirdPath, fourthPath);
                            if(game){
                                setTimeout(() => {
                                    if(model.isMobile) setupDefaultCats.call(this, true);
                                    this.startGame({
                                        gameID: game.gameId,
                                        mode: secondPath == model.getStr('mpRoute_Game_Play') ? 'money' : 'fun',
                                    });
                                }, 0);
                                return;
                            }

                            this.redirTo404Page();
                        }
                    });
                    setupDefaultCats.call(this, false);
                    return;
                }
                else if(firstPath === model.redeemLangObj(Enum.MultiPage.Promotions)){
                    if(secondPath && thirdPath){
                        _tmpCats.push(Enum.CATEGORY_IDS.PROMO_INFO);
                        model.mpPromoLoad = {
                            preRoute: secondPath,
                            route: thirdPath,
                        };
                        setupCats.call(this);
                        return;
                    }
                    if(secondPath){
                        _tmpCats.push(Enum.CATEGORY_IDS.PROMO_INFO);
                        model.mpPromoLoad = {
                            route: secondPath,
                        };
                        setupCats.call(this);
                        return;
                    }
                }
                else if(firstPath === model.redeemLangObj(Enum.MultiPage.Tournaments)){
                    if(secondPath && thirdPath){
                        _tmpCats.push(Enum.CATEGORY_IDS.PROMO_INFO);
                        model.mpPromoLoad = {
                            preRoute: secondPath,
                            route: thirdPath,
                        };
                        setupCats.call(this);
                        return;
                    }
                }
                else{
                    if(this.handleExternalPages(_tmpCats)) return;

                    for(var key in Enum.UIContexts){
                        var uiContext = Enum.UIContexts[key];
                        var modalRoute = model.getStr('mpRoute_' + key);

                        if((!secondPath && firstPath == modalRoute) || secondPath == modalRoute){
                            if(model.urls.standalonePages?.[key]){
                                this.sendWebResRequest(model.urls.standalonePages[key]);
                                _tmpCats.push(Enum.CATEGORY_IDS.WEBRES);
                                setupCats.call(this);
                                return;
                            }

                            this.whenAppReady(() => this.goTo(uiContext));
                            setupDefaultCats.call(this, false);
                            return;
                        }
                    }
                }
            }

            multipage.every((path, index) => {
                var cats = model.categories['level' + (index + 1)].filter(cat => model.redeemLangObj(cat.path) === path);
                if(!cats.length){
                    catsLegit = false;
                    return false;
                }

                cats.every(cat => {
                    if(index === 0 || cat.parent === _tmpCats[index - 1]){
                        _tmpCats.push(cat);
                        return false;
                    }
                    return true;
                });

                return true;
            });

            if(!catsLegit){
                this.redirTo404Page();
                return;
            }

            for(var i = 0, len = _tmpCats.length; i < len; i++){
                var cat = _tmpCats[i];
                if(i === 0) continue;

                if(cat.parent !== _tmpCats[i - 1]){
                    catsLegit = false;
                    break;
                }
            }

            if(!catsLegit){
                this.redirTo404Page();
                return;
            }

            setupCats.call(this);
        }

        function setupCats(){
            this.setupInitialCats(_tmpCats);
        }

        function setupDefaultCats(updatePaths){
            var firstCat = model.categories.level1[0];

            model.currCatIds = {
                level1: firstCat.id,
            };

            if(!updatePaths) return;

            var paths = getNonMultiPagePaths.call(this);
            firstCat.path && paths.push(model.redeemLangObj(firstCat.path));
            this.updatePathname(paths);
        }
    },
    setupInitialCats(_tmpCats){
        model.currCatIds = {};
        _tmpCats.forEach((cat, index) => {
            var val = cat;
            if(isObject(val)) val = val.id;
            model.currCatIds['level' + (index + 1)] = val;
        });
    },
    redirTo404Page(){
        if(model.isUI('FUND')){
            this.multipageRedir(['error'], true, true);
            return;
        }

        this.multipageRedir(['page-not-found'], true, true);
    },
    multipageRedir(multipage, redirect, replace, options, queryParams){
        options = options || {};

        if(typeof replace !== 'boolean') replace = false;

        multipage = removeEmptyFromArr(multipage);
        var arrPaths = getNonMultiPagePaths.call(this);
        arrPaths = arrPaths.concat(multipage);

        if(!redirect){
            this.updatePathname(arrPaths);
            return;
        }

        var paths = '/' + arrPaths.join('/');
        var redirUrl = window.location.origin + paths + window.location.search;

        if(isObject(queryParams)){
            redirUrl = addQueryStringValues(redirUrl, queryParams);
        }

        if(options.avoidLoginWheel) sessionStorage.setItem('avoid-wheel-login', true);

        window.location[replace ? 'replace' : 'assign'](redirUrl);
    },
    getMultiPagePathsToSkip(){
        var skipNum = model.multipagePathsToSkip;
        model.langAsPath && skipNum++;
        return skipNum;
    },
    getMultiPagePath(){
        var paths = this.getPaths();
        for(var i = 0, skipNum = this.getMultiPagePathsToSkip(); i < skipNum; i++) paths.shift();

        return paths.join('/');
    },
    formatMultipagePath(strPath, noOrigin, addLang){
        if(typeof noOrigin !== 'boolean') noOrigin = false;
        if(typeof addLang !== 'boolean') addLang = false;
        
        let paths = getNonMultiPagePaths.call(this);
        let d = model.subDomain.toLowerCase();
        if(paths.includes(d) && !addLang) paths.splice(paths.indexOf(d), 1);
        return addDirPath(!noOrigin ? window.location.origin : '', paths.join('/'), strPath) + window.location.search;
    }
}