import { isObject, lastProp, firstIfUndefined, removeItemFromArray } from "@helpers/utils.js"
import { reactive } from "vue"
import Enum from "@shared/enum.js";

import model from "../model.js";
import controller from "../controller/controller.js";
import { gsap } from "gsap";

import thumbnailLang from "@lang/thumbnail.js";
model.pushLang(thumbnailLang);

let currId = 0;

var DISPLAY_INFO = [
	'reels', 
	'rows', 
	'minBet', 
	'bonus', 
	'maxBet',
	'freeSpins', 
	'winLines', 
	'clusters', 
	'rowWin', 
	'sum', 
	'ways', 
	'jackpot',
	'hyperClusters',
];

var DISPLAY_INFO_LENGTH = DISPLAY_INFO.length;

export default class GameThumbnailModel {
	constructor(params) {
		this.id = currId++;
		this.gameId = params['gameId'];
		this.name = params['name'];

		this.categories = params['categories'];
		if (!Array.isArray(this.categories))
			this.categories = [this.categories];

		this.srcNewGameImg = model.urls.assets.newGameBadge;

		this.imgSrc = params['imgPath'];
		this.imgSrcError = params['imgPathError'];
		this.imgBadgeSrc = params['imgBadgeSrc'];
		this.imgBadgeId = params['imgBadgeId'];
		this.newGame = params['newGame'];
		this.gameJurisdiction = params['gameJurisdiction'];
		this.hasPlayFun = typeof params['hasPlayFun'] === 'boolean' ? params['hasPlayFun'] : true;
		this.gameGroup = params['gameGroup'];
		this.productID = params['productID'];
		this.tableId = params['tableId'];
		this.displayBetOnImg = Boolean(params['displayBetOnImg']);
		this.nameVisible = Boolean(model.unwrapObjDev(params['nameVisible']));
		this.payoutMechanic = params['payoutMechanic'] || '';
		this.denom = params['denom'];
		this.microgamingParams = params['microgamingParams'];
		this.langPos = params['langPos'];
		this.date = params['date'];
		this.popularity = params['popularity'];
		this.showMoreThId = params['showMoreThId'];

		this.categoryTourIcon = reactive({value: null});

		if (isObject(this.denom) && this.denom.prod && this.denom.test)
			this.denom = this.denom[model.isProd ? 'prod' : 'test'];

		this.hasDenoms = Object.keys(this.denom || {}).length > 0;

		this.pConfig = controller.getProviderConfig(params);
		this.providerDN = controller.getProviderDN(this.pConfig);
		this.allowedByCCode = this.pConfig?.allowedByCCode || [];
		this.notAllowedByCCode = this.pConfig?.notAllowedByCCode || [];
		this.comingSoon = Boolean(this.pConfig?.comingSoon);

		this.betMulti = this.pConfig && this.pConfig.multipliers;

		this.arrInfo = [];
		this.infoDisplayed = false;
		this.originalPos = {};

		for (var i = 0, j = DISPLAY_INFO_LENGTH; i < j; i++) {
			var info = DISPLAY_INFO[i];
			var val = params[info];
			this[info] = val;
		}

		this.stickySymbols = params['stickySymbols'];
		this.multipliers = params['multipliers'];
		this.respin = params['respin'];

		this.imgPos = params['imgPos'];
		this.imgPosBig = params['imgPosBig'];

		this.bgImg = reactive({value: null});
		this.bgImgNarrow = reactive({value: null});

		this.bgImgLoaded = reactive({value: false});
		this.bgImgNarrowLoaded = reactive({value: false});

		this.fav = false;
		this.freeGamesAmount = null;
		this.animTrigger = undefined;
		this.mobileTap = false;
		this.favLoading = false;

		this.openingHoursActive = true;
		this.currencySupported = true;

		this.hidden = {};
		for (var i = 0, j = this.categories.length; i < j; i++) this.hidden[this.categories[i]] = false;

		this._setupGridLayout(params['arrGrid']);

		this.jackpotNames = params.jackpotNames;
		if (this.jackpotNames && !Array.isArray(this.jackpotNames)) this.jackpotNames = [this.jackpotNames];

		this.hasJackpot = {
			value: Boolean(
				(Array.isArray(this.jackpotNames) && this.jackpotNames.length > 0) ||
				(this.productID === Enum.ProductIDs.Microgaming && this.jackpot)
			),
		};

		if (this.hasJackpot.value) {
			switch (this.productID) {
				case Enum.ProductIDs.Microgaming:
					this.jpApiLabel = 'microgamingJackpot';
					break;
				case Enum.ProductIDs.NetEntSlot:
					this.jpApiLabel = 'netEntJackpot';
					break;
			}

			this.hasJackpot.value = Boolean(controller.getExtAPI(this.jpApiLabel));
			if (this.hasJackpot.value) {
				this.jackpotInfo = reactive({
					amount: 0,
					currencyCode: 'EUR',
					setInfo: info => this._handleJPAnimation(info),
				});
			}
		}

		this._defineBetProp('_minBet', 'minBet', this._updateMinBet);
		this._defineBetProp('_maxBet', 'maxBet', this._updateMaxBet);

		this._setupBetOnImg(params);
		this.animDisplayNum = Math.floor(Enum.GameThumbnail.maxAnimDisplayNum * Math.random()) + 1;
	}

	_defineBetProp(prop, propObs, callback){
		this[prop] = this[propObs];
		if(typeof this[prop] !== 'undefined'){
			this.displayBetOnImg && callback.call(this);
		}
		else delete this[propObs];
	}

	isDisabled(){
		return (
			!this.openingHoursActive || 
			(model.logged ? !this.currencySupported : false)
		);
	}

	isAllowedByCCode(){
		var userCCode = model.observables.userCountry;
		return (
			!userCCode || 
			(
				(!this.allowedByCCode.length || this.allowedByCCode.includes(userCCode)) && 
				(!this.notAllowedByCCode.length || !this.notAllowedByCCode.includes(userCCode))
			)
		);
	}

	setHidden = function(categoryID, value){
		this.hidden[categoryID] = value;
	}

	addHiddenByCat = function(categoryID){
		this.hidden[categoryID] = false;
	}

	getGameId(){
		return controller.unwrapGameID(this.gameId);
	}

	/**
	 * Some game IDs tend to be objects which contain mobile / desktop ID versions
	 * @return {Object / Integer} An object which contains both desktop and mobile game IDs, or an Integer. 
	 */
	getRawGameId(){
		return this.gameId;
	}

	/**
	 * Improves performance to render game info (number of reels, rows, etc.) only once the info has to 
	 * be actually displayed to the end user, instead of rendering it upon page load for every thumbnail at once. 
	 */
	renderInfo(){
		if(!this.infoDisplayed){
			this._updateMinBet();
			this._updateMaxBet();

			for(var i = 0, j = DISPLAY_INFO_LENGTH; i < j; i++){
				var info = DISPLAY_INFO[i];
				var val = this[info];
				if((typeof val !== 'undefined' && val != null)){
					if(this.displayBetOnImg && (info == 'minBet' || info == 'maxBet')) continue;

					this.arrInfo.push({
						key: info,
						value: val,
					});
				}
			}
			this.infoDisplayed = true;
		}
		else this.reRenderBet();
	}

	/**
	 * Min and max bet values must always be re-rendered whenever a user hovers over a game thumbnail. 
	 * The default currency is Euros, which means that the bet values should be re-rendered so that the 
	 * user could get the amount in their own currency after login. 
	 */
	reRenderBet(){
		this._updateMinBet();
		this._updateMaxBet();
	}

	/**
	 * Returns the thumbnail image which should be preloaded (either "normal" or "grid"). 
	 * @param  {Integer|String} categoryID See the description for the grid "categories" config property. 
	 * @return {Object}                    Image properties. 
	 */
	getImgToLoad(categoryID/*, isNarrow*/){
		// typeof isNarrow !== 'boolean' && (isNarrow = false);

		var props = {};

		var grid = this.getGrid(categoryID);
		if(grid){
			props = {
				loaded: grid.bgImgLoaded,
				imgSrc: grid.imgSrc,
				imgObservable: grid.bgImg,
			};
		}
		else{
			props = {
				loaded: this.bgImgLoaded,
				imgSrc: this.imgSrc,
				imgObservable: this.bgImg,
			};
		}

		if(this.imgSrcError)
			props.imgSrcError = this.imgSrcError;

		return reactive(props);
	}

	/**
	 * Checks if the specialized CSS Grid layout logic should be used for a particular thumbnail. 
	 * @param  {Integer|String} categoryID See the description for the grid "categories" config property. 
	 * @return {Boolean}                   True if the grid layout logic should be executed, false otherwise. 
	 */
	getGrid(categoryID){
		if(!Array.isArray(this.arrGrid)) return null;

		var targetDomain = model.preferredTopGamesDomain || model.subDomain;

		return this.arrGrid.find((item) => {
			let ccode = (item.ccode + '').toUpperCase();
			let domain = (targetDomain + '').toUpperCase();

			var matchesCCode = item.ccode ? ccode == domain : !item.ccode;
			return item.categoryID == categoryID && matchesCCode;
		});
	}

	removeGrid(categoryID){
		var grid = this.arrGrid.find(item => item.categoryID == categoryID);
		grid && this.arrGrid.splice(this.arrGrid.indexOf(grid), 1);
	};

	updateGridPositions(grid){
		if(!grid) return;

		if(isObject(grid.window)){
			var pos = grid.window;

			Object.keys(pos).forEach(key => {
				pos[key] = model.redeemLangObj(pos[key]);
			});

			var ww = window.innerWidth;
			if(ww >= 1281 && pos.LG){
				grid.obsColumn = pos.LG.column; 
				grid.obsRow = pos.LG.row;
			}
			else if(ww >= 1024 && pos.MD){
				grid.obsColumn = pos.MD.column; 
				grid.obsRow = pos.MD.row;
			}
			else if(ww >= 480 && pos.SM){
				grid.obsColumn = pos.SM.column; 
				grid.obsRow = pos.SM.row;
			}
			else{
				var last = lastProp(pos);
				if(last){
					grid.obsColumn = last.column; 
					grid.obsRow = last.row;
				}
			}
		}
		else{
			grid.obsColumn = grid.column;
			grid.obsRow = grid.row;
		}
	}

	getGridRowSize(categoryID){
		return this._getGridDim(categoryID, true);
	}

	getGridColumnSize(categoryID){
		return this._getGridDim(categoryID, false);
	}

	getGridRowPos(categoryID){
		return this._getGridPos(categoryID, true);
	}

	getGridColumnPos(categoryID){
		return this._getGridPos(categoryID, false);
	}

	_getGridPos(categoryID, isRow){
		return this._deduceGrid(categoryID, isRow, dimSplit => parseInt(dimSplit[0]));
	}

	_getGridDim(categoryID, isRow){
		return this._deduceGrid(categoryID, isRow, dimSplit => parseInt(dimSplit[1]) - parseInt(dimSplit[0]));
	}

	_deduceGrid(categoryID, isRow, callback){
		if(typeof isRow !== 'boolean') isRow = true;

		var grid = this.getGrid(categoryID);
		if(grid){
			var dim = isRow ? grid.obsRow : grid.obsColumn;
			if(typeof dim === 'string' && dim.includes('/')){
				var dimSplit = dim.split('/');
				if(dimSplit.length === 2)
					return callback(dimSplit);
			}
		}
		return 1;
	}

	/**
	 * Sets up all CSS Grid layout properties from the config. Since the latest version of IE does not fully 
	 * support the grid layout, the grid properties will not be applied. 
	 * @param  {Object} grid Has the following properties: 
	 *                        - {String} imgName => Partial path to the image. 
	 *                        - {String} column => The column width or position of the thumbnail.
	 *                        - {String} row => The row height or position of the thumbnail.
	 *                        - {Array|Any} categories => The target categories which should apply the above grid logic. 
	 */
	_setupGridLayout(arrGrid){
		if(!model.gridLayoutSupported || !Array.isArray(arrGrid) || !arrGrid.length) return;

		this.arrGrid = arrGrid;
		this.arrGrid.forEach(grid => this.setGrid(grid, false));
	}

	setGrid(grid, _add){
		['obsColumn', 'obsRow', 'bgImg', 'bgImgLoaded'].forEach(label => grid[label] = reactive({value: grid[label]}));
		grid = reactive(grid);

		if(this.arrGrid){
			if(typeof _add !== 'boolean') _add = true;
			grid.obsColumn.value = undefined;
			grid.obsRow.value = undefined;
			grid.imgSrc = grid.imgPath;
			grid.bgImg.value = undefined;
			grid.bgImgLoaded.value = undefined;
			_add && this.arrGrid.push(grid);
		}
		else this._setupGridLayout([grid]);

		return grid;
	}

	removeGrid(grid){
		removeItemFromArray(this.arrGrid, grid);
	}

	/**
	 * Updates a bet value to the user's own currency. After the user 
	 * logs out, the value will be converted back to Euros.
	 */
	_updateBet(prop, propObs, mathFunctName){
		if(isObject(this.pConfig) && Array.isArray(this.pConfig.unsupportedCurrencies)){
			this.currencySupported = !this.pConfig.unsupportedCurrencies.includes(model.getCurrency().code);
			if(model.logged && !this.currencySupported){
				this[propObs] = '--';
				return;
			}
		}

		var denom = this._getDenom(mathFunctName);
		var betMulti = this._getBetMulti(propObs);

		if(this.payoutMechanic.toLowerCase() === 'betline' && typeof this.winLines === 'number')
			this[propObs] = this[prop] * this.winLines * denom * betMulti;
		else
			this[propObs] = this[prop] * denom * betMulti;
	}

	_updateMinBet(){
		return this._updateBet('_minBet', 'minBet', 'min');
	}

	_updateMaxBet(){
		return this._updateBet('_maxBet', 'maxBet', 'max');
	}

	_getBetMulti(strProp){

		if(!isObject(this.betMulti))
			return 1;

		var betMulti = this.betMulti[model.getCurrency().code];
		if(!isObject(betMulti))
			return 1;

		betMulti = betMulti[strProp];
		if(typeof betMulti !== 'number')
			return 1;

		return betMulti;
	}

	_getDenom(mathFunctName){
		var currency = model.getCurrency();
		return this.hasDenoms ? Math[mathFunctName].apply(null, firstIfUndefined(this.denom, currency.code)) : 0.01;
	}

	/**
	 * Sets up the min / max bet values on the thumbnails themselves, instead of on the game info panel. 
	 * @param  {Object} params All parameters derived from the constructor. 
	 */
	_setupBetOnImg(params){
		if(!this.displayBetOnImg)
			return;

		this.openFromTime = null;
		this.openInHours = null;
		this.openingTimeText = null;

		var openingTimeText = '24/7';

		if(typeof params.openFromTime === 'number' && typeof params.openInHours === 'number'){
			params.openFromTime = parseInt(params.openFromTime);
			params.openInHours = parseInt(params.openInHours);

			this.openFromTime = params.openFromTime;
			this.openInHours = params.openInHours;

			var openFromTimeMinutes = parseInt(params.openFromTimeMinutes);
			var openInMinutes = parseInt(params.openInMinutes);

			this.openFromTimeMinutes = !isNaN(openFromTimeMinutes) ? openFromTimeMinutes : null;
			this.openInMinutes = !isNaN(openInMinutes) ? openInMinutes : null;

			var dateFrom = new Date();
			dateFrom.setUTCHours(params.openFromTime);
			dateFrom.setUTCMinutes(this.openFromTimeMinutes || 0);

			var dateTo = new Date();
			dateTo.setUTCHours(params.openFromTime + params.openInHours);
			dateTo.setUTCMinutes(this.openInMinutes || 0);

			var localTimeHrs = {
				from: dateFrom.getHours(),
				to: dateTo.getHours(),
			};

			var openingTimeLabel = (localTimeHrs.from != 12 ? (localTimeHrs.from % 12) : 12) + ':' + this._toDoubleDigits(dateFrom.getMinutes()) + ' ' + (localTimeHrs.from >= 12 ? 'PM' : 'AM');
			var closingTimeLabel = (localTimeHrs.to != 12 ? (localTimeHrs.to % 12) : 12) + ':' + this._toDoubleDigits(dateTo.getMinutes()) + ' ' + (localTimeHrs.to >= 12 ? 'PM' : 'AM');
			openingTimeText = openingTimeLabel + ' - ' + closingTimeLabel;
		}

		this.openingTimeText = openingTimeText;
	}

	_toDoubleDigits(num){
		if(num < 10)
			return '0' + num;
		return num;
	}

	_handleJPAnimation(info){
		if(typeof info !== 'object' || info === null) return;

		if(this.jpTween && this.prevJPCurrency && this.prevJPCurrency !== info.currencyIsoCode){
			this.jpTween.kill();
			this.jpTween = null;
		}

		var props = {
			currValue: info.startAtValue,
		};

		if(!this.jackpotInfo.amount) this.jackpotInfo.amount = props.currValue;

		this.jpTween && this.jpTween.kill();
		this.jpTween = createTween.call(this);

		this.prevJPCurrency = info.currencyIsoCode;

		function createTween(){
			return gsap.to(props, {
				duration: info.numberOfSeconds,
				currValue: info.endAtValue,
				onUpdate: () => this.jackpotInfo.amount = props.currValue,
				onComplete: () => {
					this.jpTween.kill();
					delete this.jpTween;
				},
			});
		}
	}
}