import Enum from "@shared/enum.js";

export default {
	"casino": {
		"viewId": Enum.Views.MAIN,
		"path": [
			"casino/4stars/casino-[site-key]",
			"casino/fund/casino-[site-key]"
		],
		"expectingGames": true
	},
	"page-not-found": {
		"viewId": Enum.Views.PAGE_NOT_FOUND,
		"path": [
			"page-not-found/page-not-found-[site-key]"
		],
		"expectingGames": false
	},
	"no-support-cy": {
		"viewId": Enum.Views.NO_SUPPORT_CY,
		"path": [
			"no-support/no-support-[site-key]"
		],
		"expectingGames": false
	},
	"no-support": {
		"viewId": Enum.Views.NO_SUPPORT,
		"path": [
			"no-support/no-support-[site-key]"
		],
		"expectingGames": false
	},
	"landing-page": {
		"viewId": Enum.Views.FUND_LP,
		"path": [
			"landing-page/landing-page"
		],
		"expectingGames": true
	},
	"landing-page-crm-first-dep": {
		"viewId": Enum.Views.CRM,
		"path": [
			"landing-page/crm/landing-page-crm-first-dep"
		],
		"expectingGames": false
	},
	"landing-page-crm-second-dep": {
		"viewId": Enum.Views.CRM,
		"path": [
			"landing-page/crm/landing-page-crm-second-dep"
		],
		"expectingGames": false
	},
	"landing-page-crm-offer": {
		"viewId": Enum.Views.CRM,
		"path": [
			"landing-page/crm/landing-page-crm-offer"
		],
		"expectingGames": false
	},
	"landing-page-crm-duplica": {
		"viewId": Enum.Views.CRM,
		"path": [
			"landing-page/crm/landing-page-crm-duplica"
		],
		"expectingGames": false
	},
	"landing-page-crm-duplica100": {
		"viewId": Enum.Views.CRM,
		"path": [
			"landing-page/crm/landing-page-crm-duplica100"
		],
		"expectingGames": false
	},
	"blog": {
		"viewId": Enum.Views.BLOG,
		"path": [
			"blog/blog"
		],
		"expectingGames": true
	},
	"web-res-tc": {
		"viewId": Enum.Views.TC,
		"path": [
			"webres-presenter"
		],
		"expectingGames": false
	},
	"web-res-btc": {
		"viewId": Enum.Views.BTC,
		"path": [
			"webres-presenter"
		],
		"expectingGames": false
	},
	"web-res-ft": {
		"viewId": Enum.Views.FREE_BET,
		"path": [
			"webres-presenter"
		],
		"expectingGames": false
	},
	"cashier": {
		"viewId": Enum.Views.CASHIER,
		"path": [
			"empty-page"
		],
		"expectingGames": false
	},
	"manifiesto": {
		"viewId": Enum.Views.MANIFIESTO,
		"path": [
			"manifiesto/manifiesto"
		],
		"expectingGames": false
	},
	"welcomefs-lp": {
		"viewId": Enum.Views.WELCOME_FS,
		"path": [
			"welcomefs/welcomefs-lp"
		],
		"expectingGames": false
	},
	"welcomefs-register": {
		"viewId": Enum.Views.WELCOME_FS,
		"path": [
			"welcomefs/welcomefs-register"
		],
		"expectingGames": false
	},
	"vccp-register": {
		"viewId": Enum.Views.VCCP_REGISTER,
		"path": [
			"vccp-register/vccp-register"
		],
		"expectingGames": false
	},
	"vccp-register-100": {
		"viewId": Enum.Views.VCCP_REGISTER,
		"path": [
			"vccp-register/vccp-register-100"
		],
		"expectingGames": false
	},
	"vccp-register-crazytime": {
		"viewId": Enum.Views.VCCP_REGISTER,
		"path": [
			"vccp-register/vccp-register-crazytime"
		],
		"expectingGames": false
	},
	"vccp-register-ruletaespanol": {
		"viewId": Enum.Views.VCCP_REGISTER,
		"path": [
			"vccp-register/vccp-register-ruletaespanol"
		],
		"expectingGames": false
	},
	"playstore-redirect": {
		"viewId": Enum.Views.PLAYSTORE_REDIRECT,
		"path": [
			"playstore-redirect"
		],
		"expectingGames": false
	},
}