import model from "@js/model.js";
import { arrayFirst, isUndefined, isObject, addQueryStringValues, toHex, firstIfUndefined } from "@helpers/utils.js"
import { reactive } from "vue"
import Enum from "@shared/enum.js";

import cookieHelper from "@helpers/cookie-helper.js";
import { OpenWithdrawalForm } from "@lang/cashier.js";
import { ViewPrivacyPolicyLabel, ViewTermsCondLabel } from "@lang/login.js";
import { AcceptTerms, AcceptPrivacy, LogInLabel } from "@lang/register.js";
import dynamicImportLoad from "@helpers/dynamic-import-load.js";
import { RespGamingLabel, RespGamingNotifText } from "@lang/misc.js";

export default  {
	initLogin(){
		model.pushLang({
			OpenWithdrawalForm, 
			ViewPrivacyPolicyLabel, 
			ViewTermsCondLabel, 
			AcceptTerms,
			AcceptPrivacy,
			LogInLabel,
			RespGamingLabel,
			RespGamingNotifText,
		});

		model.registerConfig = model.registerConfig || {};
		if(Array.isArray(model.registerConfig.redirectCases)){
			model.registerConfig.redirectCases.sort((item1, item2) => {
				if(item1.ifSelCountry) return -1;
				if(item2.ifSelCountry) return 1;
				return 0;
			});
		}
		
		model.on('ServerResponseSuccess', async e => {
			var action = e.action;
			switch(action.actionType){
				case "UserLogin":
					this.updateLoginStatus(action);
					await this.updateUserProfile(action);
					await this.handleGetPlayerInfo(action);
					await this.handleUserDataManipulation(action);
					this.handle_login(action, e.$xml);
					this.handle_keepAlive(action);
					await this.loadNotificationsFromUserLogin();
					break;
				case "UserRegistration":
					this.handle_registration(action, e.$xml);
					break;
				case 'AccountActivation':
					await this.handleGetPlayerInfo(action);
					await this.handleUserDataManipulation(action);
					this.handle_login(action, e.$xml);

					/*
						This event is bound outside of the 
						minified files (e.g. regarding facebook 
						pixel and google analytics), do not remove.
						*/
					model.dispatchEvent('ActivationComplete', {
						action: action,
					});

					if([Enum.SiteIDs.COM_4SG, Enum.SiteIDs.FUND_COM].includes(model.siteId)){
						this.showNotification({
							title: model.getStr('RespGamingLabel'),
							text: model.getStr('RespGamingNotifText'),
							buttons: [
								{
									type: Enum.ButtonTypes.SUCCESS,
									label: model.getStr('YesLabel'),
									onClick: () => this.goTo(Enum.UIContexts.LIMITS),
								},
								{
									type: Enum.ButtonTypes.INFO,
									label: model.getStr('Close'),
								},
							],
							closeDisabled: true,
						});
					}
					break;
				case "ForgottenPassword":
					this.goTo(Enum.UIContexts.LOGIN_RESET_PASSWORD, true);
					this.showClientMessage(action);
					break;
				case "PasswordReset":
					var main = arrayFirst(action.messages.message, msg => msg.type == 'Main' && msg.passwordReset);
					main && this.sendLoginRequest(main.passwordReset.username, main.passwordReset.newPassword, false);
					break;
				case 'BlueberryStartP2PSession':
					(await this.loginBusiness()).handleBlueberryStartP2PSession.apply(this, arguments);
					break;
				case 'BlueberryAuthP2PSession':
					await this.handleGetPlayerInfo(action);
					await this.handleUserDataManipulation(action);
					this.handle_login(action, e.$xml);
					break;
				case 'PrivacyAcceptance' : 
					this.handleTermsAcceptance(action, 'privacyAccepted');
					break;
				case 'TermsAcceptance' : 
					this.handleTermsAcceptance(action, 'termsAccepted');
					break;
			}
		});

		model.on('LoginComplete', 'RegistrationComplete', e => cookieHelper.set('registered_player', 1, 365));

		model.on("LoginComplete", () => {
			this.startGameAfterLogin();
			this.redirectUserAfterLogin();
			this.closeAllModals();

			model.showOnStart && this.goTo(model.showOnStart);

			model.refreshCMeter = true;

			model.dispatchEvent('HideMobileNav');
		});

		if(isUndefined(model.displayLoginAfterLogout))
			model.displayLoginAfterLogout = true;

		model.on("LogoutComplete", e => {
			if(!e.isPageLoad){
				if(model.displayLoginAfterLogout)
					this.goTo(Enum.UIContexts.LOGIN, true);
				else{
					this.closeAllModals();
					model.dispatchEvent('HideMobileNav');
				}
				this.destroyAllGames();
			}

			model.canCloseEgoeGame = true;
			model.dispatchEvent('PossibleGameResume', {check: false});

			var arrModelPropsToDelete = [
				'brokenGame',
				'termsAcceptance',
				'personalSettings',
				'withdraw',
				'paymentMethods',
				'playerPaymentInfo',
				'mainAccount',
				'promotions',
				'userProfile',
				'prevDeposits',
				'userId',
				'subGames',
				'uToken',
				'paymentTransactionList',
				'notifications',
				'limits',
				'betsoft',
				'sportBetting',
				'referral',
			];

			for(var i = 0, j = arrModelPropsToDelete.length; i < j; i++)
				delete this.model[arrModelPropsToDelete[i]];
		});

		if(model.webglDetect !== Enum.WebGL.ENABLED){
			dynamicImportLoad(async () => {
				const { WebGLError } = await import("@lang/misc.js");
				model.pushLang({WebGLError});
			}).then(() => {
				this.showNotification({
					title: model.getStr('warning'),
					text: model.getStr('WebGLError'),
				});
			})
		}

		model.on('LoginComplete', () => {
			if(!model.termsAcceptance) return;

			dynamicImportLoad(async () => {
				const { PrivacyPolicyAfterLoginText, TermsAndCondAfterLoginText } = await import('@lang/login.js');
				const { UpdateTermsTitle } = await import("@lang/login.js");
				model.pushLang({PrivacyPolicyAfterLoginText, TermsAndCondAfterLoginText, UpdateTermsTitle});
			}).then(() => this.loginBusiness().then(module => module.handleTermsAfterLogin.call(this)));
		});

		// setupSpaceBus.call(this);
	},
	loginBusiness(global){
		return this.getBusiness('__loginBusiness', () => import("../business/login-business.js"), global);
	},
	encryptPassword(){
		let args = Array.prototype.slice.call(arguments);
		
		loadCryptoJS.call(this).then(() => {
			let callback = args[args.length - 1];
			let passwords = args.slice(0, args.length - 1);
			
			passwords.forEach((pass, index) => {
				passwords[index] = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(CryptoJS.MD5(pass).toString()))
			});
			
			callback.apply(this, passwords);
		});

		function loadCryptoJS(){
			return new Promise(resolve => this.getScript(model.urls.cryptoJS, true).then(() => resolve()));
		}
	},
	formatPhoneNumberForReq(code, number){
		code = '' + code;
		if(code.indexOf('00') == 0) code = code.replace('00', '+');

		number = '' + number;
		if(number.substr(0, 1) == '0') number = number.substr(1);

		return code + '-' + number;
	},
	handleTermsAcceptance(action, prop){
		var msgMain = arrayFirst(action.messages.message, msg => msg.type == 'Main' && msg.termsAcceptance);
		if(!msgMain?.termsAcceptance?.response) return;
		if(model.termsAcceptance) model.termsAcceptance[prop] = Boolean(msgMain.termsAcceptance.response.termsUpdated);
	},
	sendLoginRequest(username, password, encrPass, success) {
		if(typeof encrPass !== 'boolean') encrPass = true;

		encrPass ? this.encryptPassword(password, encryptedPassword => sendReq.call(this, encryptedPassword)) : sendReq.call(this, password);

		async function sendReq(pass){
			let params = {
				uid: username,
				pwd: pass,
			};

			(await this.loginBusiness()).addLoginData.call(this, params);
			this.sendRequest("UserLogin", params, success);
		}
	},
	sendRegistrationRequest(params) {
		this.sendRequest("UserRegistration", params);
	},
	sendActivationRequest(activationCode, showClientFailure) {
		let params = {
			codeType: sessionStorage.getItem('activation-code'),
			code: activationCode,
		};

		this.loginBusiness().then(module => {
			/*model.storeLoginQS && */module.addLoginData.call(this, params);
			this.sendRequest("AccountActivation", params, null, {showClientFailure});
		});
	},
	sendForgottenPassword(params) {
		this.sendRequest("ForgottenPassword", params);
	},
	sendLogoutRequest() {
		this.sendRequest("UserLogout");
	},
	sendPasswordReset(params) {
		this.sendRequest("PasswordReset", params);
	},
	startPasswordReset(changeCode) {
		model.passwordResetCode = changeCode
		this.goTo(Enum.UIContexts.LOGIN_RESET_PASSWORD, true)
	},
	handle_login() {
		this.loginBusiness().then(module => module.handleLogin.apply(this, arguments));
	},
	handle_registration() {
		this.loginBusiness().then(module => module.handleRegistration.apply(this, arguments));
	},
	logout() {
		this.loginBusiness().then(module => module.logout.apply(this, arguments));
	},
	sendCheckUsernameExists(){
		this.loginBusiness(false).then(module => module.sendCheckUsernameExists.apply(this, arguments));
	},
	sendBlueberryStartP2PSession() {
		this.loginBusiness().then(module => module.sendBlueberryStartP2PSession.apply(this, arguments));
	},
	sendBlueberryAuthP2PSession(blueberryId, blueberryCode, sessionId) {
		this.sendRequest("BlueberryAuthP2PSession", {blueberryId, blueberryCode, sessionId});
	},
}

// function setupSpaceBus(){
// 	var props = ['win', 'deposit', 'friendWin'];
// 	model.spaceBusAmounts = reactive({});

// 	props.forEach(key => model.spaceBusAmounts[key] = 0);

// 	model.on('LoginComplete', 'LogoutComplete', () => {
// 		if(model.logged){
// 			model.spaceBusCode = toHex(model.userId);
// 			model.spaceBusLink = addQueryStringValues(window.location.origin, {
// 				bus: model.spaceBusCode,
// 			});
// 		}
// 		else{
// 			model.spaceBusCode = null;
// 			model.spaceBusLink = null;
// 		}
// 	});

// 	model.on('LoginComplete', 'LogoutComplete', 'SetLang', () => {
// 		if(!isObject(model.spaceBusVals)) return;

// 		var currency = model.getCurrency();
// 		if(model.hideOtherPromoAmountValues) currency = model.getCurrency('EUR');

// 		props.forEach(key => {
// 			model.spaceBusAmounts[key] = currency.symbol_native + firstIfUndefined(model.spaceBusVals[key], currency.code);
// 		});
// 	});
// }